import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  DialogState,
  DialogType,
} from '../../ViewModel/dialogViewModel/dialogViewModel';
import { getTrackingInfoForCalendarNextButtonClick } from '@wix/bookings-analytics-adapter';
import { bookingsContactInfoSaveSuccess } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  mapFlowTypeToBiProperties,
  mapStateToProperties,
} from '../../../../utils/bi/mappers';
import { isBookingsEnabled } from '../../../../utils/serviceUtils/serviceUtils';
import { Service } from '@wix/bookings-uou-types';
import {
  deprecatedGetDynamicPricingInfo,
  getDynamicPricingInfo,
} from '../../../../utils/dynamicPricing/dynamicPricing';
import { isSlotNoBookFlow } from '../../../../utils/slotAvailability/slotAvailability';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { navigateToBookingsServicePage } from '@wix/bookings-catalog-calendar-viewer-navigation';
import { navigateToBookingsFormPage } from '@wix/bookings-booking-navigation';
import {
  BookingsQueryParams,
  getCurrentPath,
  isBookingFormInstalled as isBookingFormInstalledUtils,
  isTemplateMode,
  openPreviewPremiumModal,
  trackAnalytics,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { Preference } from '../../../../types/types';
import { DO_NOT_CARE_STAFF } from '../../../../constants/constants';

export type NavigateToNextPage = ({
  selectedSlots,
  isWaitingListFlow,
}: {
  selectedSlots: SlotAvailability[];
  isWaitingListFlow: boolean;
}) => Promise<void>;

export function createNavigateToNextPageAction(
  actionFactoryParams : ActionFactoryParams<calendarstate, CalendarContext="">,
): NavigateToNextPage {
  return async ({ selectedSlots, isWaitingListFlow }) => {
    const { getControllerState, context } = actionFactoryParams;
    const [state, setState] = getControllerState();
    const { biLogger, activeFeatures, preset, flowAPI, experiments } = context;
    const {
      servicesInView,
      selectedTime,
      rescheduleBookingDetails,
      selectedBookingPreferences,
    } = state;

    const selectedSlot = selectedSlots[0];
    const selectedStaffBookingPreference = selectedBookingPreferences?.find(
      (bookingPreference) => bookingPreference.key === Preference.STAFF_MEMBER,
    );
    if (selectedStaffBookingPreference?.value === DO_NOT_CARE_STAFF) {
      delete selectedSlot.slot?.resource;
    }
    const selectedServicesIds =
      selectedSlot.nestedSlots!.length > 1
        ? selectedSlot.nestedSlots!.map((slot) => slot.serviceId!)
        : [selectedSlot.slot?.serviceId!];
    const selectedServices = servicesInView.filter((service) =>
      selectedServicesIds?.includes(service.id),
    )!;

    const isTemplate = isTemplateMode({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });

    const isBookingEnabled = isBookingsEnabled({
      servicesTypes: selectedServices.map((service) => service.info.type),
      activeFeatures: activeFeatures!,
      isTemplateMode: isTemplate,
    });
    const siteIsEcom = await context.calendarApi.isEcomSite();
    if (isBookingEnabled && siteIsEcom) {
      const isNoBookFlow = isSlotNoBookFlow(selectedSlot);
      const isReschedulingFlow = !!rescheduleBookingDetails;
      const isBookingFormInstalled = await isBookingFormInstalledUtils(
        flowAPI.controllerConfig.wixCodeApi,
      );

      void biLogger.report(
        bookingsContactInfoSaveSuccess({
          selectedSlot: selectedTime,
          ...mapFlowTypeToBiProperties({
            isWaitingList: isWaitingListFlow,
            isBookingFormInstalled,
            isNoBookFlow,
            properties: mapStateToProperties({ state, context }),
          }),
        }),
      );

      if (isNoBookFlow) {
        handleNoBookFlow({
          selectedService: selectedServices[0],
          context,
        });
      } else if (isReschedulingFlow) {
        handleReschedulingFlow(actionFactoryParams);
      } else if (isWaitingListFlow) {
        handleWaitingListFlow(actionFactoryParams);
      } else {
        await handleRegularBookFlow(
          actionFactoryParams,
          selectedSlot,
          selectedServices,
        );
      }
    } else {
      if (flowAPI.environment.isPreview && !isBookingEnabled) {
        if (experiments.enabled('specs.bookings.usePremiumPreviewModal')) {
          setState({
            dialog: {
              type: DialogType.PremiumPreview,
              state: DialogState.IDLE,
            },
          });
        } else {
          // only one service is supported in preview mode
          const serviceType = selectedServices[0].info.type;

          await openPreviewPremiumModal({
            wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
            appParams: flowAPI.controllerConfig.appParams,
            serviceType,
            referralInfo: preset,
          });
        }
      }

      if (flowAPI.environment.isViewer) {
        setState({
          dialog: {
            type: siteIsEcom
              ? DialogType.PremiumViewer
              : DialogType.siteIsNotEcom,
            state: DialogState.IDLE,
          },
        });
      }
    }
  };
}

const handleWaitingListFlow = ({
  getControllerState,
}: ActionFactoryParams<calendarstate, CalendarContext="">) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.JoinWaitlist,
      state: DialogState.IDLE,
    },
  });
};

const handleReschedulingFlow = ({
  getControllerState,
}: ActionFactoryParams<calendarstate, CalendarContext="">) => {
  const [, setState] = getControllerState();

  setState({
    dialog: {
      type: DialogType.RescheduleConfirm,
      state: DialogState.IDLE,
    },
  });
};

const handleNoBookFlow = ({
  selectedService,
  context,
}: {
  selectedService: Service;
  context: CalendarContext;
}) => {
  const {
    preset: referral,
    experiments,
    flowAPI: {
      controllerConfig: { wixCodeApi },
    },
  } = context;
  const serviceSlug = selectedService!.info.slugs[0];

  navigateToBookingsServicePage(
    wixCodeApi,
    {
      serviceSlug,
      referral,
    },
    experiments.enabled('specs.bookings.useCustomUrlSegmentsForSlug'),
  );
};

const handleRegularBookFlow = async (
  {
    getControllerState,
    context,
  }: ActionFactoryParams<calendarstate, CalendarContext="">,
  slotAvailability: SlotAvailability,
  selectedServices: Service[],
) => {
  const {
    businessInfo,
    experiments,
    preset,
    isCartEnabled,
    calendarSelections,
    flowAPI: {
      controllerConfig: { wixCodeApi },
    },
    subscriptions: { onNextClicked },
  } = context;
  const [state, setState] = getControllerState();
  const { selectedTimezone, selectedVariantsOptions } = state;

  try {
    const isMultiServiceAppointmentEnabled = experiments.enabled(
      'specs.bookings.multiServiceAppointment',
    );

    const isMultiServiceAppointment =
      isMultiServiceAppointmentEnabled && slotAvailability.nestedSlots?.length;

    const serviceSlug = selectedServices[0]!.info.slugs[0];
    const continueShoppingUrl =
      isCartEnabled && !isMultiServiceAppointment
        ? `/${getCurrentPath(wixCodeApi).join('/')}?${
            BookingsQueryParams.TIMEZONE
          }=${state.selectedTimezone}`
        : undefined;

    const trackingInfo = getTrackingInfoForCalendarNextButtonClick({
      services: selectedServices,
      businessName: businessInfo?.name || '',
    });

    trackAnalytics({
      wixCodeApi,
      eventId: trackingInfo.eventId as any,
      payload: trackingInfo.payload,
    });

    const dynamicPriceInfo = isMultiServiceAppointmentEnabled
      ? getDynamicPricingInfo({
          selectedSlot: slotAvailability,
          servicesSelections: calendarSelections?.services?.length
            ? calendarSelections.services
            : [
                {
                  id: selectedServices[0].id,
                  variants: selectedVariantsOptions,
                },
              ],
        })
      : deprecatedGetDynamicPricingInfo(selectedVariantsOptions);

    setState(
      { calendarStatus: CalendarStatus.NAVIGATING },
      { immediate: true },
    );

    const shouldNavigate =
      !onNextClicked ||
      (await onNextClicked({ service: selectedServices[0], slotAvailability }))
        ?.shouldNavigate;

    if (shouldNavigate) {
      void navigateToBookingsFormPage({
        wixCodeApi,
        platformAPIs: context.flowAPI.controllerConfig.platformAPIs,
        slotAvailability,
        serviceSlug,
        dynamicPriceInfo,
        queryParams: {
          [BookingsQueryParams.REFERRAL]: preset,
          [BookingsQueryParams.TIMEZONE]: selectedTimezone!,
          ...(isCartEnabled
            ? {
                [BookingsQueryParams.CONTINUE_SHOPPING_URL]:
                  continueShoppingUrl,
              }
            : {}),
        },
      });
    }
  } catch (e) {
    setState({ calendarStatus: CalendarStatus.IDLE }, { immediate: true });
  }
};
</calendarstate,></calendarstate,></calendarstate,></calendarstate,></void>